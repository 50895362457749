import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ProductPrice, Button } from '@ten24/slatwallreactlibrary/components'
import { addToCart } from '../../actions/cartActions'
import { useProductPrice, useFormatCurrency } from '@ten24/slatwallreactlibrary/hooks'
import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'
import { useLivePrice } from '../../hooks/useLivePrice'
import { isAccountVerified, checkInvetory, showPriceForUnverifiedAccounts } from '@ten24/slatwallreactlibrary/selectors'
import { ProductQuantityInput } from './ProductQuantityInput'

const ProductForm = ({ sku, isDisabled, isLoading, productUrl }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const checkInvetoryFlag = useSelector(checkInvetory)
  const { isFetchingLivePrice, quantityBreakPrice, salePrice, validForPurchase, surCharge, surChargeDescription } = useLivePrice(sku)
  const showPriceForUnverifiedAccountsFlag = useSelector(showPriceForUnverifiedAccounts)
  const verifiedAccountFlag = useSelector(isAccountVerified)
  const { showAddToCart } = useProductPrice({ salePrice: salePrice, listPrice: sku?.listPrice })
  const [quantity, setQuantity] = useState(1)
  const [quantityPriceBreak, setQuantityPriceBreak] = useState(salePrice)
  const [formatCurrency] = useFormatCurrency({})
  useEffect(() => {
    setQuantityPriceBreak(salePrice);
    quantityBreakPrice.filter(function (item) {

      if (item.to === 0 && item.from !== 0) {
        item.to = 99999; // If the last entry in the list is zero, then the upper bound is infinite.
      }
      if (quantity >= item.from && quantity <= item.to) {
        setQuantityPriceBreak(item.amount);
      }
    });
  }, [salePrice, quantity])

  if (checkInvetoryFlag && (!sku || sku?.erpQoh < 1)) return (
    <div style={{ marginRight: '5px' }}>
      <span className='text-accent'> {t('frontend.checkout.stock')}</span>
    </div>
  )
  return (
    <>
      {!isFetchingLivePrice && !validForPurchase &&
        <div className="p-2 border rounded w-100 d-flex mt-3 mb-3 align-items-center">
          <i className="fal fa-exclamation-circle mr-2"></i>
          <p className="text-sm mb-0">{t('frontend.product.price.missing')}</p>
        </div>
      }
      {!isFetchingLivePrice && validForPurchase && surCharge > 0 && (
        <>
          <div className="p-2 border rounded w-100 mt-3 mb-3 align-items-center">
            <div className="d-flex">
              <i className="fal fa-exclamation-circle mr-2"></i>
              <p className="text-sm mb-0">{t('frontend.product.price.surchargeOne') + ' ' }<span class="badge badge-primary mr-1 text-sm">{surCharge + '% surcharge '}</span>{t('frontend.product.price.surchargeTwo')} </p>
            </div>
            {surChargeDescription && <p className="text-sm mb-0"><b>Category:</b> {surChargeDescription}</p>}
          </div>
        </>
      )}
      {!isFetchingLivePrice && !!validForPurchase && <ProductPrice salePrice={quantityPriceBreak} listPrice={sku.listPrice} />}
      {(verifiedAccountFlag || showPriceForUnverifiedAccountsFlag) && (
        <form className="mb-grid-gutter" onSubmit={e => e.preventDefault()}>
          <div className="form-group d-flex align-items-start">
            {isFetchingLivePrice && (
              <>
                <span> </span>
                <i className="far fa fa-spinner fa fa-spin font-size-lg mr-2" />
              </>
            )}
            {isAuthenticated() && showAddToCart && validForPurchase && (
              <>
                <ProductQuantityInput setQuantity={setQuantity} quantity={quantity} sku={sku} stock={sku.erpQoh} />
                <Button
                  disabled={isDisabled}
                  isLoading={isLoading}
                  className="btn btn-primary btn-block"
                  onClick={event => {
                    dispatch(addToCart(sku.skuID, quantityPriceBreak, true, quantity, t('frontend.checkout.item_added_successfully')))
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }}
                >
                  <i className="far fa-shopping-cart font-size-lg mr-2"></i>
                  {t('frontend.product.add_to_cart')}
                </Button>
              </>
            )}
          </div>
          {quantityBreakPrice && quantityBreakPrice.filter(item => item.hasOwnProperty('from')).length > 1 && (
            <table>
              {quantityBreakPrice.map((qtyBreak) => {
                return (
                  <tr className="price-qty-break pt-1">
                    <td className="pr-2">{ qtyBreak.label }</td>
                    <td>{`${formatCurrency(qtyBreak.amount)}`}</td>
                  </tr>
                )
              })}
            </table>
          )}
          {!isAuthenticated() && (
            <div className="alert alert-warning d-flex flex-column" role="alert">
              {t('frontend.pdp.loginToView')} <Link to={`/my-account?redirect=/product/${productUrl}`}>{t('frontend.account.login')}</Link>
            </div>
          )}
        </form>
      )}
    </>
  )
}
export { ProductForm }
// a
